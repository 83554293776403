/** ------------------------------------------- GafInfoMsg (GafInfoMsg.js 대상)  Start ------------------------------------------- */
import Gaf from '@/ui/pi/common/Gaf.js'
import GafMainFunc from '@/ui/pi/common/GafMainFunc.js'
import piCommonUtil from '@/ui/pi/common/piCommonUtil'

export default {
  chkInfoMsgs: function (lv_Vm) {
    let infoMsgList = []
    /*
      상해상품 정보성메세지 뭍힘현상으로 체크위치 수정 (pnlpql00110 에서 이동) 2017.07.24
      1. 심사대상상품유형코드(ZZJDG_OBJ_P_T_ID) 50(신상해)이고
      1.1 차량코드선택 되어 있으면 (2016.12.19 박영현선임 요청) 메세지발생 -> 차량선택 안한 경우는 pnlpql00110 에서 체크하고 넘어옴
        : '설계하신 상품은 상해상품입니다.\n직업상해요율 상이시 반송될 수 있습니다. 주의요망합니다.'
    */
    let zzjdgObjPTId = GafMainFunc.getIfbcValue('ZZJDG_OBJ_P_T_ID') // 심사대상상품유형코드RD
    if (zzjdgObjPTId === '50') {
      infoMsgList.push(piCommonUtil.toBigSpace('설계하신 상품은 상해상품입니다.<br>직업상해요율 상이시 반송될 수 있습니다.<br>주의요망합니다.'))
    }

    // 보험료 계산시 정보성메세지 순서체크 추가   2017.01.05
    /*
          보험료 계산 시, 정보성 메세지 추가  2017.01.04
          체크조건 김민수사원 확인
          1. 연금저축골드(유/무배당), 승계용 연금저축골드(유/무배당) -> 상품코드가 'LP0236 or LP0346' 이면 info msg
          2. (가입후종피부가)내리사랑연금                             -> 상품코드가 'LP0320' 이면 info msg
          3. 아이 2종 중 [의료수급권자]임신질환실손특약               -> 선택 보험 중 가설대표분류코드가 'I055001BNNNAU01' 이 있을때
    */
    let zzprcdDId = GafMainFunc.getIfbcValue('ZZPRCD_D_ID') // 상품코드
    let infoMsg = ''

    if (!piCommonUtil.isEmpty2(zzprcdDId)) {
      zzprcdDId = zzprcdDId.substring(0, 6)
      /* 연금저축류 보험료계산 팝업 삭제 요청자:김민정선임 2017.07.25
          if(['LP0236','LP0346','LP0321','LP0322','LP0323','LP0324'].indexOf(zzprcdDId) > -1){
              infoMsg =  '연금저축골드연금보험은 [유배당]과 [무배당] 중\n' +
                        '고객님께서 비교 후 가입하실 수 있으니\n' +
                        '상품설명서(제안용) 內\n' +
                        '<배당여부에 따른 연금저축 상품 비교>를\n반드시 확인하시기 바랍니다.';
          }
      */
      if (['LP0320'].indexOf(zzprcdDId) > -1) {
        infoMsg = '※ 내리사랑연금보험은 하나의 연금으로<br>' +
                              ' (조)부모의 노후자금과 (손)자녀의 필요자금을<br>' +
                              ' 동시에 준비하는 세대연생 연금보험으로<br>' +
                              ' 가입시 종피보험자를 선택하지 않은 경우 <br>' +
                              ' 연금개시전까지 종피보험자를 선택할 수 있습니다.'
      }

      if (!piCommonUtil.isEmpty2(infoMsg)) {
        infoMsgList.push(piCommonUtil.toBigSpace(infoMsg))
      }
    }

    // 선택보험 조회
    let covInfo = Gaf.getVObj('PNLPQL00109_CoverageInfo')
    // let infoMsg = ''

    if (piCommonUtil.isObj(covInfo)) {
      let checkRow = Gaf.getVObj('PNLPQL00109_CoverageInfo').filter(function (item) { return item.isChecked === true })

      if (checkRow.length !== 0) {
        checkRow.forEach((el, i) => {
          if (el.uiCode === 'I055002BNNNAU01') {
            infoMsg = '※ [의료급여수급권자]임신질환실손입원의료비특약을<br>' +
                                '선택하신 경우 의료급여 자격득실 확인서 또는 ' +
                                '의료급여증명서를 반드시 제출하셔야 합니다.'
          }
        })
        if (!piCommonUtil.isEmpty2(infoMsg)) {
          infoMsgList.push(piCommonUtil.toBigSpace(infoMsg))
        }
      }
    }

    // 태아형 상품 보험료 계산 시 (2021-02-23)
    let zzptclrPrdTId = GafMainFunc.getIfbcValue('ZZPTCLR_PRD_T_ID') // 특이상품유형코드RD
    if (!piCommonUtil.isEmpty2(zzptclrPrdTId) && zzptclrPrdTId === '29') {
      infoMsg = '출생예정일에는 출생예정일 기준 TP와 계약일 기준 TP의 차액만큼 추가 TP가 발생합니다.<br><br>' +
                '이 계약의 출생예정일은 계약자가 알린 날짜가 아니라,<br>' +
                '가입설계서에 기재되는 \'출생예정일(출생후 보험료 자동 적용일)\'을 말하며,<br><br>' +
                '출생예정일보다 출생통지일이 앞선 경우에는<br>출생통지일을 기준으로 합니다.'
      infoMsgList.push(infoMsg)
    }

    // 고액계약 선택 메시지 min.ko 17.01.04
    let _baseInfo00027 = Gaf.getTObj('prodInfo').baseInfoDto.gafBaseInfoListDtos.filter(function (item) { return item.code === '00027' })
    // [선심사 및 영업시스템 고도화 PJT] 선심사결과에서 보험료계산 로직 추가로 Gaf.getPanel('MSPPI820P') 조건 추가
    if ((Gaf.getPanel('MSPPI002D') || Gaf.getPanel('MSPPI820P')) &&
                piCommonUtil.isObj(_baseInfo00027) && _baseInfo00027.length > 0) {
      let infoMsg = ''
      if (Gaf.getTObj('baseContDto').zzlgatDiscFg === 'Y') { // 고액할인
        infoMsg = '고액할인을 선택하셨습니다.<br>' +
                            '선택한 고액계약 유형은 청약 후에는<br>' +
                            '변경 불가능합니다.<br>' +
                            '고액할인 대상이 아닌 계약은<br>' +
                            '할인액이 없으므로 선택효과가 없습니다.'
      }
      if (Gaf.getTObj('baseContDto').zzlgatAcumFg === 'Y') { // 고액적립
        infoMsg = '고액적립을 선택하셨습니다.<br>' +
                            '선택한 고액계약 유형은 청약 후에는<br>' +
                            '변경 불가능합니다.<br>' +
                            '고액적립 대상이 아닌 계약은<br>' +
                            '추가적립액이 없으므로 선택효과가 없습니다.'
      }
      if (!piCommonUtil.isEmpty2(infoMsg)) {
        infoMsgList.push(piCommonUtil.toBigSpace(infoMsg))
      }
    }

    // 건강나이차이 정보가 없는경우
    let _baseInfo00062 = Gaf.getTObj('prodInfo').baseInfoDto.gafBaseInfoListDtos.filter(function (item) { return item.code === '00062' })
    // if( isObj(u5.panel.getPanel("PNLPQL00105"))
    //         && isObj(u5.panel.getPanel("PNLPQL00105")["l00105_sec20_component80_00062"])
    //         && isEmpty2(u5.panel.getPanel("PNLPQL00105")["l00105_sec20_component80_00062"].getValue())){
    // [선심사 및 영업시스템 고도화 PJT] 선심사결과에서 보험료계산 로직 추가로 Gaf.getPanel('MSPPI820P') 조건 추가
    if ((Gaf.getPanel('MSPPI002D') || Gaf.getPanel('MSPPI820P')) && piCommonUtil.isObj(_baseInfo00062) && _baseInfo00062.length > 0) {
      let panlData040D = Gaf.getPanel('MSPPI040D')
      if (piCommonUtil.isObj(panlData040D.l00105_hageDfgrRfcYN) && panlData040D.l00105_hageDfgrRfcYN === 'Y') {
      //if (piCommonUtil.isObj(lv_Vm.$parent.$refs.MSPPI040D.l00105_hageDfgrRfcYN) && lv_Vm.$parent.$refs.MSPPI040D.l00105_hageDfgrRfcYN === 'Y') {
        infoMsg = '피보험자의 건강나이 정보가 없으므로<br>' +
        '건강나이에 따른 보험료 할인이 적용되지 않습니다.<br>' +
        '보험료 할인 적용을 원하시는 경우<br>' +
        '피보험자의 건강나이 측정 후 재설계하시기 바랍니다.'
        // infoMsgList.push(infoMsg)
      }
      if (!piCommonUtil.isEmpty2(infoMsg)) {
        infoMsgList.push(piCommonUtil.toBigSpace(infoMsg))
      }
    }

    // ASR190401294 (사랑ON)올바른성장보험 개발관련 팝업화면 개발 - 2019.04 장효상책임요청
    // (삭제)1-1. 태아보험일때로 체크하고
    // 1-1. 태아보험일때로 체크하고 (특이상품유형코드 -> 가설화면예외코드 06으로 변경요청 : 김민정프로)
    let entSrnEpId = '' // 가설화면예외코드(ex: "A1,A4")
    let prodInfo = Gaf.getTObj('prodInfo')
    if (prodInfo.baseInfoDto.zzentSrnEpId !== undefined) {
      entSrnEpId = prodInfo.baseInfoDto.zzentSrnEpId
    }
    if (!piCommonUtil.isEmpty2(entSrnEpId) && entSrnEpId.indexOf('06') > -1) {
    // let zzptclrPrdTId = GafMainFunc.getIfbcValue('ZZPTCLR_PRD_T_ID') // 특이상품유형코드RD
    // if (zzptclrPrdTId === '28' || zzptclrPrdTId === '29') {
      let covInfo_child = Gaf.getVObj('PNLPQL00109_CoverageInfo')
      if (piCommonUtil.isObj(covInfo_child)) {
        let checkRow = Gaf.getVObj('PNLPQL00109_CoverageInfo').filter(function (item) { return item.isChecked === true })
        let infoMsg = ''
        let infoMsgAdd = ''
        if (checkRow.length !== 0) {
          checkRow.forEach( (el, i) => {
            if (checkRow[i].zzinscdScCd === '54' || checkRow[i].zzinscdScCd === '49' ) {
              var zzrsrvEnSAId = GafMainFunc.getIfbcValue('ZZRSRV_EN_S_A_ID', checkRow[i].templateId)
              if (!piCommonUtil.isEmpty2(infoMsgAdd)) {
                infoMsgAdd = infoMsgAdd + ',\n' + checkRow[i].name + ' ' + zzrsrvEnSAId + '세'
              } else {
                infoMsgAdd = checkRow[i].name + ' ' + zzrsrvEnSAId + '세'
              }
            }
          })
        }
        // add메세지가 빈 값이 아니면 넣어준다. 줄바꿈은 되지않음
        if (!piCommonUtil.isEmpty2(infoMsgAdd)) {
          infoMsg = '아래 특약은 예약가입특약으로서, 향후 특약별 보장개시일에 보험료를 납입해야 합니다.<br><br> - 예약가입특약 : <br>' + infoMsgAdd
        }
        if (!piCommonUtil.isEmpty2(infoMsg)) {
          // infoMsgList.push(piCommonUtil.toBigSpace(infoMsg))
          infoMsgList.push(infoMsg)
        }
      }
    }

    // 1. CEO종신(UL,VUL)상품의 경우, 비과세메세지를 표시하지 않는다.=> 이후 프로세스 흘릴 필요 없으므로 위치를 위로 이동함.
    // var zzprcdDId = GafMainFunc.getIfbcValue('ZZPRCD_D_ID'); //상품코드;
    if (['LP0470005', 'LP0471006'].indexOf(zzprcdDId) > -1) {
      return infoMsgList
    }

    // 초기변수값 선언
    let padVal = 0 // 납입기간값
    let entAmtTot = 0 // 브라보7080연금 가입금액:일시납보험료+월납보험료*12*납입기간
    let onpadYn = false // 일시납(거치형)
    let entAmtExcdYn = false // 가입금액(주보험) 1억초과여부
    let anutBgnAge = 0 // 연금개시연령
    let contInsrdDfrncYn = false // 계피상이여부
    let chkAnutBgnAgeYn_54 = false // 연금개시연령체크여부(만54세이하:true)
    let fullAge = null // 만나이

    // 1.주피보험자 연금개시연령 설정
    // let prodInfo = Gaf.getTObj('prodInfo')

    if (piCommonUtil.isObj(Gaf.getTObj('baseContDto').zaAnutBgnAge)) {
      anutBgnAge = Gaf.getTObj('baseContDto').zaAnutBgnAge
    } else {
      for (var i = 0; i < prodInfo.baseInfoDto.gafBaseInfoListDtos.length; i++ ) {
        if (prodInfo.baseInfoDto.gafBaseInfoListDtos[i].displayText === '연금개시연령') {
          anutBgnAge = prodInfo.baseInfoDto.gafBaseInfoListDtos[i].defaultValue
        }
      }
    }

    // 2.만나이 설정 (fullAge)
    // zzcontvInsrdCd  21인 주피보험자
    // 가상고객 : 입력나이 세팅
    // 가망고객 : zzleglAgeVl 세팅
    let rltnrInfo_21 = Gaf.getTObj('rltnrInfo').filter(function (item) { return item.zzcontvInsrdCd === '21' })[0]
    fullAge = rltnrInfo_21.zzleglAgeVl

    // 3.연금개시연령이 만54세이하 체크//NumCal(tmpStndAmt, elj.zaMulVal, '*')
    // If(연금개시연령(anutBgnAge) + (만나이 – 주피보험자 보험나이) < 55)
    if (piCommonUtil.NumCal(anutBgnAge, piCommonUtil.NumCal(fullAge, rltnrInfo_21.ageatentryVl, '-'), '+') < 55) {
      chkAnutBgnAgeYn_54 = true
    }

    // 4.계약자, 주피자 상이할 경우
    // Pnlpql00105 동일고객여부체크함수fnEQCust 와 동일로직으로 처리
    // 동일고객여부체크함수
    let fnEQCust = function (_rltnrInfo1, _rltnrInfo2) {
      if (!piCommonUtil.isObj(_rltnrInfo1) || !piCommonUtil.isObj(_rltnrInfo2)) {
        return false
      }
      if (_rltnrInfo1.ageatentryVl === _rltnrInfo2.ageatentryVl &&
                  _rltnrInfo1.zaInsrdNm === _rltnrInfo2.zaInsrdNm &&
                  _rltnrInfo1.genderCd === _rltnrInfo2.genderCd) {
        return true
      }
      return false
    }

    let rltnrInfo_11 = Gaf.getTObj('rltnrInfo').filter(function (item) { return item.zzcontvInsrdCd === '11' })[0]
    contInsrdDfrncYn = !fnEQCust(rltnrInfo_11, rltnrInfo_21)

    // 5.납입기간, 가입금액 구하기
    // 선택한 보험목록을 모두 체크한다.
    let coverageList = Gaf.getVObj('PNLPQL00109_CoverageInfo')
    coverageList.forEach((el, i) => {
      if (!el.isChecked) {
        return true
      }

      // 5.1 주보험이고 - 상품보험관계구성코드(ZZPDINS_R_C_C_ID) “01”
      if (el.elementprodtpCd === '10') {
        // var _mainCov = Gaf.getVObj('PNLPQL00109_CoverageInfo')[0];  //주보험 추출
        let sPadPrd = el.pmprdTypSelected.key.split(',')
        // 5.1.1 납기유형코드가 세납일 때
        if (sPadPrd[0] === '02') {
          padVal = piCommonUtil.NumCal(sPadPrd[1], rltnrInfo_21.ageatentryVl, '-')
        } else { // 5.3 납기유형코드가 세납일 아닐 때
          padVal = sPadPrd[1]
        }

        // 5.1.2 가입금액구하기
        if (padVal > 0) {
          // 가입금액 * 12 * 납입기간 * 가입금액단위
          // entAmtTot += _mainCov .covInsAmt * 12 * padVal * _mainCov.unit;
          entAmtTot = piCommonUtil.NumCal(entAmtTot, GafMainFunc.getUnitAmt(piCommonUtil.NumCal(piCommonUtil.NumCal(el.covInsAmt, 12, '*'), padVal, '*'), el.unit), '+')
        } else {
          // 가입금액 * 가입금액단위
          // entAmtTot += _mainCov .covInsAmt * padVal * _mainCov.unit;
          entAmtTot = piCommonUtil.NumCal(entAmtTot, GafMainFunc.getUnitAmt(el.covInsAmt, el.unit), '+')
        }
      } else { // 5.2 주보험 아닌경우 (연금통합형일시납의 경우)
        // 5.2.1
        // if (상품보험관계구성코드(ZZPDINS_R_C_C_ID) “17” AND 가설화면예외코드(ZZENT_SRN_EP_ID) ”03”){
        // entAmtTot += covInsAmt * unit;
        // }
        let _ZZPDINS_R_C_C_ID = GafMainFunc.getIfbcValue('ZZPDINS_R_C_C_ID', el.templateId)
        Gaf.d('_ZZPDINS_R_C_C_ID : ' + _ZZPDINS_R_C_C_ID)
        // GafAppMainDto -> GafTmodelDto -> GafProdInqrDto -> GafBaseInfoDto -> zzentSrnEpId(가설화면예외코드);
        if (piCommonUtil.isObj(_ZZPDINS_R_C_C_ID) && (_ZZPDINS_R_C_C_ID === '17') &&
                  Gaf.getTObj('prodInfo').baseInfoDto.zzentSrnEpId === '03') {
          entAmtTot = piCommonUtil.NumCal(entAmtTot, GafMainFunc.getUnitAmt(el.covInsAmt, el.unit), '+')
        }
      }
    })

    // 6. 가설종목코드 or 주보험납입주기코드로 일시납(거치형)여부를 판단

    // 6.1가설종목코드 Gaf.gafObject.tmodel.baseContDto.zzentplLineCd 가 아래 값을 포함하거나
    // '19, 20, 26, 31, 32, 33, 34, 35, 36, 37, 38, 61, 89, 90, 91, 92, 94, 95, 96, 97, W2, E2, H2, H4, D4, F3'
    // Gaf.gafObject.tmodel.baseContDto.zaPadCylCd 납입주기코드가 “00” 인 경우
    if (['19', '20', '26', '31', '32', '33', '34', '35', '36',
      '37', '38', '61', '89', '90', '91', '92', '94', '95',
      '96', '97', 'W2', 'E2', 'H2', 'H4', 'D4', 'F3'].indexOf(Gaf.getTObj('baseContDto').zzentplLineCd) > -1 ||
      Gaf.getTObj('baseContDto').zaPadCylCd === '00') {
      onpadYn = true
    }

    // 7. 가입금액(주보험) 1억초과여부 체크
    if (entAmtTot > 100000000) {
      entAmtExcdYn = true
    }

    // ㅁ 세법관련 안내문구추가 조건별 메시지 내용
    // 1. CEO종신(UL,VUL)상품의 경우, 비과세메세지를 표시하지 않는다.
    /*
    var zzprcdDId = GafMainFunc.getIfbcValue('ZZPRCD_D_ID'); //상품코드;
    if(['LP0470005','LP0471006'].indexOf(zzprcdDId) > -1){
      return infoMsgList;
    }
      */
    let zzentSrnEpId = Gaf.getTObj('prodInfo').baseInfoDto.zzentSrnEpId
    if (piCommonUtil.isEmpty2(zzentSrnEpId)) {
      zzentSrnEpId = ''
    // }else{
    // zzentSrnEpId = zzentSrnEpId.split(',')[0]; //무조건 첫번째값만 사용함:설계자 요청
    }

    switch (true) {
      // 2.가설화면예외코드(ZZENT_SRN_EP_ID) ”01”, “02” 를 포함하고
      case zzentSrnEpId.indexOf('A1') > -1 || zzentSrnEpId.indexOf('A2') > -1 :

        // 2.1 계약자.주피자 상이할 경우
        if (contInsrdDfrncYn) {
          infoMsgList.push(entAmtExcdYn ? lv_Vm.$t('pi')['TAXRAW_MSG_09_1'] : lv_Vm.$t('pi')['TAXRAW_MSG_09_2'])
        }

        // 2.2연금개시연령이 55세 미만인 경우
        // if(anutBgnAge < 55){
        if (chkAnutBgnAgeYn_54) {
          infoMsgList.push(entAmtExcdYn ? lv_Vm.$t('pi')['TAXRAW_MSG_10_1'] : lv_Vm.$t('pi')['TAXRAW_MSG_10_2'])
        }

        // 2.3 2.1번, 2.2번 조건이 아니고 가설화면예외코드(ZZENT_SRN_EP_ID) ”01”, “02” 를 포함할 때
        infoMsgList.push(entAmtExcdYn ? lv_Vm.$t('pi')['TAXRAW_MSG_06_1'] : lv_Vm.$t('pi')['TAXRAW_MSG_06_2'])

        break

      case zzentSrnEpId.indexOf('A3') > -1 :

        // 3.1 계약자.주피자 상이할 경우
        if (contInsrdDfrncYn) {
          infoMsgList.push(entAmtExcdYn ? lv_Vm.$t('pi')['TAXRAW_MSG_07_1'] : lv_Vm.$t('pi')['TAXRAW_MSG_07_2'])
        }
        // 3.2 연금개시연령이 55세 미만인 경우
        // if(anutBgnAge < 55){
        if (chkAnutBgnAgeYn_54) {
          infoMsgList.push(entAmtExcdYn ? lv_Vm.$t('pi')['TAXRAW_MSG_08_1'] : lv_Vm.$t('pi')['TAXRAW_MSG_08_2'])
        }

        // 3.3 가설화면예외코드(ZZENT_SRN_EP_ID) ”03” 인 경우
        infoMsgList.push(entAmtExcdYn ? lv_Vm.$t('pi')['TAXRAW_MSG_02_1'] : lv_Vm.$t('pi')['TAXRAW_MSG_02_2'])

        break

      case zzentSrnEpId.indexOf('A4') > -1 :

        // 4.1 가설종목코드가 '거치형(일시납포함상품)'의 경우
        if (onpadYn) {
          if (contInsrdDfrncYn) {
            infoMsgList.push(entAmtExcdYn ? lv_Vm.$t('pi')['TAXRAW_MSG_07_1'] : lv_Vm.$t('pi')['TAXRAW_MSG_07_2'])
          }

          // 연금개시연령 55세 미만
          // if(anutBgnAge < 55){
          if (chkAnutBgnAgeYn_54) {
            infoMsgList.push(entAmtExcdYn ? lv_Vm.$t('pi')['TAXRAW_MSG_08_1'] : lv_Vm.$t('pi')['TAXRAW_MSG_08_2'])
          }
          infoMsgList.push(entAmtExcdYn ? lv_Vm.$t('pi')['TAXRAW_MSG_02_1'] : lv_Vm.$t('pi')['TAXRAW_MSG_02_2'])
        } else if (Gaf.getTObj('baseContDto').zaPadCylCd === '12') { // 4.2 납입주기 월납인경우
          if (padVal >= 5) { // 납입기간 5년이상
            if (contInsrdDfrncYn) {
              infoMsgList.push(lv_Vm.$t('pi')['TAXRAW_MSG_01']) // 계피상이
            }
            // if(anutBgnAge < 55){ // 연금개시연령 55세 미만
            if (chkAnutBgnAgeYn_54) { // 연금개시연령이 만54세 이하
              infoMsgList.push(lv_Vm.$t('pi')['TAXRAW_MSG_08_3'])
            }
            infoMsgList.push(lv_Vm.$t('pi')['TAXRAW_MSG_02_3'])
          } else { // 납입기간이 5년미만의 경우 {
            infoMsgList.push(entAmtExcdYn ? lv_Vm.$t('pi')['TAXRAW_MSG_02_1'] : lv_Vm.$t('pi')['TAXRAW_MSG_02_2'])
          }
        }

        break

      case zzentSrnEpId.indexOf('A5') > -1 :

        // 5.1 일시납(거치형)의 경우
        if (onpadYn) {
          infoMsgList.push(entAmtExcdYn ? lv_Vm.$t('pi')['TAXRAW_MSG_04_1'] : lv_Vm.$t('pi')['TAXRAW_MSG_04_3'])
        } else if (Gaf.getTObj('baseContDto').zaPadCylCd === '12') { // 5.2납입주기 월납인경우
          if (padVal >= 5) { // 납입기간이 5년이상
            infoMsgList.push(lv_Vm.$t('pi')['TAXRAW_MSG_04_2'])
          } else if (padVal < 5) { // 납입기간이 5년미만의 경우
            infoMsgList.push(lv_Vm.$t('pi')['TAXRAW_MSG_04_3'])
          }
        }

        break

      case zzentSrnEpId.indexOf('A7') > -1 && !(['01', '79', '82', '84'].indexOf(Gaf.getTObj('baseContDto').zzentplLineCd) > -1) :

        // 6.1납입주기 월납인경우
        if (Gaf.getTObj('baseContDto').zaPadCylCd === '12') {
          if (padVal >= 5) { // 납입기간이 5년이상
            infoMsgList.push(lv_Vm.$t('pi')['TAXRAW_MSG_04_2'])
          }
        } else { // 6.2 그외의 경우(비월납 or 일시납 포함)
          infoMsgList.push(lv_Vm.$t('pi')['TAXRAW_MSG_05_3'])
        }

        break
      case zzentSrnEpId.indexOf('A6') > -1 : // A6일때 팝업 삭제 김민정님 요청

        break
        // 7. 그외상품
      default :

        if (Gaf.getTObj('baseContDto').zaPadCylCd === '12') {
          if (padVal >= 5) { // 납입기간이 5년이상
            infoMsgList.push(lv_Vm.$t('pi')['TAXRAW_MSG_05_1'])
          }
        } else { // 그외의 경우
          infoMsgList.push(lv_Vm.$t('pi')['TAXRAW_MSG_05_2'])
        }
    }// end case

    return infoMsgList
  }
}
/** ------------------------------------------- GafInfoMsg End   ------------------------------------------- */
